import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (

  <Layout>
    <SEO title="Home" keywords={[`ishaqinc`, `consulting`]} />
      <section className="hero is-white is-fullheight-with-navbar">
          <div className="hero-body">
              <div className="container">

              </div>
          </div>
      </section>
  </Layout>
);

export default IndexPage
